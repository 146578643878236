import styled from '@emotion/styled'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { formatPayerAlias } from '@pure/libs/PhoneNumberHelper'

import { useForm } from '@my-drifter/hooks/useForm'
import Images from '@my-drifter/libs/Images'
import { getFigmaTextFromVariable } from '@my-drifter/libs/TextRepository'
import Texts from '@my-drifter/libs/Texts'
import { Variables } from '@my-drifter/libs/Variables'
import { SignInViewProps } from '@my-drifter/types/SignInTypes'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { FigmaTextWithStyle } from './FigmaTextWithStyle'
import { SIGN_IN_BUTTON_ID } from './startSignInWithCode'
import TextFieldForm from './TextFieldForm'

const ERROR_MESSAGE = 'Ange nummer'
const ValidationSchema = Yup.object()
  .shape({
    phone: Yup.string()
      .min(10, ERROR_MESSAGE)
      .test('phone', ERROR_MESSAGE, (text) => formatPayerAlias(text).length > 0)
  })
  .required()

const SignInEnterPhoneNumber = (props: SignInViewProps) => {
  const { isLoading } = props

  const formProps = useForm(ValidationSchema, { defaultValues: { phone: '' } })

  const _onPressContinue = (phone: string) =>
    props.onPressContinue({ ...props.signInState, data: { ...props.signInState.data, phone } })

  const onSubmit = () => {
    if (!formProps.getValues('phone')) return
    return _onPressContinue(formProps.getValues('phone'))
  }

  return (
    <Box fullWidth fullHeight align="center" top spacing={Spacings.xxxl}>
      <FigmaImageContainer imageKey={Images.drifterLogoDark} width={300} />
      <Container fullWidth top spacing={Spacings.xxxl}>
        <Box bottom spacing={Spacings.m}>
          <FigmaTextWithStyle type="headingsH1Small" text={getFigmaTextFromVariable(Variables['VariableID:26:3846'])} />
        </Box>
        <FigmaText textKey={Texts.textLoginTxtLoginInputPhoneNumber} />
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <Box fullWidth top spacing={Spacings.s}>
            <Controller
              control={formProps.control}
              name="phone"
              render={({ field, fieldState }) => (
                <TextFieldForm
                  {...field}
                  value={field.value}
                  fullWidth
                  fieldState={fieldState}
                  autoFocus
                  placeholder="+46"
                />
              )}
            />
          </Box>
          <Box fullWidth top spacing={Spacings.xxl}>
            <Button
              fullWidth
              text={getFigmaTextFromVariable(Variables['VariableID:362:6744'])}
              type="submit"
              id={SIGN_IN_BUTTON_ID}
              loading={isLoading}
            />
          </Box>
        </form>
        <Box fullWidth align="center" top spacing={Spacings.xxl}>
          <FigmaTextWithStyle type="body2Regular" text={getFigmaTextFromVariable(Variables['VariableID:26:3850'])} />
          <Box top spacing={Spacings.xxs}>
            <FigmaTextWithStyle type="body2Regular" text={getFigmaTextFromVariable(Variables['VariableID:26:3851'])} />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const Container = styled(Box)`
  max-width: 350px;
`

export default SignInEnterPhoneNumber
