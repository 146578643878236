const FontSans = 'Poppins'

export const BodySmall = {
  fontFamily: FontSans,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.4px'
}

export const BodyMedium = {
  fontFamily: FontSans,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.25px'
}

export const LabelMedium = {
  fontFamily: FontSans,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.5px'
}

export const LabelMediumStrong = {
  ...LabelMedium,
  fontStyle: 'normal',
  fontWeight: 700
}

export const TitleSmall = {
  fontFamily: FontSans,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.1px'
}

export const TitleSmallStrong = {
  ...TitleSmall,
  fontWeight: 600
}

export const TitleMedium = {
  fontFamily: FontSans,
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.15px'
}

export const TitleMediumStrong = {
  ...TitleMedium,
  fontWeight: 600
}

export const TitleLarge = {
  fontFamily: FontSans,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '28px',
  letterSpacing: '0px'
}
