import { Timestamp } from 'firebase-admin/firestore'

export const PlaceCode = {
  Gate: 'GATE',
  Inside: 'INSIDE',
  Outside: 'OUTSIDE',
  Entry: 'ENTRY',
  Exit: 'EXIT'
} as const

export type PlaceCode = (typeof PlaceCode)[keyof typeof PlaceCode]

export const PlaceType = {
  Slot: 'Slot',
  Gate: 'Gate'
} as const

export type PlaceType = (typeof PlaceType)[keyof typeof PlaceType]

export type CameraEvent = {
  id: string
  cam_code: string
  device_id: number
  created_at?: Timestamp
  expires_at?: Timestamp
  lid?: number // Camera image ID: it is optional to reflect middleware DB. Real events always contain the data, but we're making it optional for guest parking permits and session emulator purposes.
  place_code?: PlaceCode | string // F-17, undefined if its snowing and the camera is covered
  place_type: PlaceType
  plate_txt?: string // ABC000, can be undefined if there is no car
  plate_confidance?: number
  record_time: Timestamp
  record_time_string?: string // This seems to not be set see any record in firestore
}

export type SiteCameraEvent = CameraEvent & {
  siteId: string
}

export interface SiteCameraEventDTO extends Omit<SiteCameraEvent, 'record_time' | 'created_at' | 'expires_at'> {
  created_at: string
  expires_at: string
  record_time: string
}

export type StrictCameraEvent = Required<Pick<CameraEvent, 'cam_code' | 'place_code' | 'place_type' | 'plate_txt'>>
