import { Box, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import Images from '@my-drifter/libs/Images'
import { SlotStats } from '@my-drifter/libs/SiteMapHelper'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { BodySmall, LabelMediumStrong } from '@my-drifter/libs/Typography'

import FigmaImage from './FigmaImage'
import { DRAWER_TABLE_WIDTH } from './HardcodedSizes'
import { PortalColors } from './PortalColors'
import { PortalSpacings } from './PortalSpacings'

const StyledHeaderCell = styled(TableCell)(() => ({
  ...LabelMediumStrong,
  textTransform: 'uppercase',
  color: PortalColors.textPrimary,
  borderBottom: 'none'
}))

const StyledTableCell = styled(TableCell)(() => ({
  ...BodySmall,
  border: 0,
  color: PortalColors.textPrimary,
  '&:last-child td, &:last-child th': {
    border: PortalColors.borderDecorativeLow,
    background: PortalColors.surfaceWhite,
    ...LabelMediumStrong
  }
}))

interface ParkingStatsTableProps {
  slotStats: SlotStats
}

export default function ParkingStatsTable({ slotStats }: ParkingStatsTableProps) {
  const { available, guests, occupied, regular, permits, offenses, chargeSlotOffenses } = slotStats
  const rows: { name: string; value: string | number; className?: string }[] =
    occupied === 0
      ? [{ name: getFigmaText(TextsExtended.noParkedCars), value: '' }]
      : [
          { name: getFigmaText(TextsExtended.sitestypeRegular), value: regular },
          { name: getFigmaText(TextsExtended.sitestypePermits), value: permits },
          { name: getFigmaText(TextsExtended.guestParking), value: guests },
          { name: getFigmaText(TextsExtended.sitesparkingOffence), value: offenses },
          { name: getFigmaText(TextsExtended.sitestypeOffenceEv), value: chargeSlotOffenses, className: 'offense' }
        ].filter((row) => row.value !== 0)
  rows.push({
    name: getFigmaText(TextsExtended.sessionStatisticssessionsBreakdowncolumnTitleTotal),
    value: `${occupied} / ${available}`
  })

  const parkedText = getFigmaText(TextsExtended.sitesheadingSlots)
  return (
    <TableContainer
      component={Box}
      sx={{
        borderRadius: '4px',
        border: `1px solid ${PortalColors.borderDecorativeLow}`,
        boxShadow: 'none',
        marginBottom: PortalSpacings.x6
      }}
    >
      <Table
        sx={{ minWidth: DRAWER_TABLE_WIDTH, borderCollapse: 'separate', borderSpacing: 0 }}
        aria-label="parking stats table"
        size="small"
      >
        <TableHead>
          <TableRow>
            <StyledHeaderCell>{getFigmaText(TextsExtended.status)}</StyledHeaderCell>
            <StyledHeaderCell align="right">{parkedText}</StyledHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              className={row.className}
              sx={{
                background: PortalColors.surfaceLevelSecond,
                borderColor: PortalColors.borderDecorativeLow,
                '&:last-child td, &:last-child th': {
                  background: PortalColors.surfaceWhite,
                  ...LabelMediumStrong,
                  textTransform: 'uppercase'
                },
                '&.offense': {
                  background: PortalColors.surfaceError
                }
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: PortalSpacings.x2 }}>
                  {row.className === 'offense' && <FigmaImage imageKey={Images.error} />}
                  {row.name}
                </Box>
              </StyledTableCell>
              <StyledTableCell align="right">{row.value}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
