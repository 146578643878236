import { collection, query } from 'firebase/firestore'

import Collections from '@contracts/enums/Collections'
import { LiveSiteMapStats } from '@contracts/types/LiveSiteMapStats'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import { db } from '@my-drifter/libs/FirebaseOptions'

export function useGetLiveSiteMapStats(siteId?: string) {
  const liveSiteMapStatsQuery = query(
    collection(db, `${Collections.SITES}/${siteId}/${Collections.LIVE_SITE_MAP_STATS}`)
  )
  return useFirestoreQuery<LiveSiteMapStats[]>(liveSiteMapStatsQuery, {
    fnName: 'useGetLiveSiteMapStats',
    enabled: !!siteId
  })
}
