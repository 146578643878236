import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

import { HikerUserRole } from '@contracts/types/HikerUser'

import { GetSessionStatsForSiteResponse } from '@functions-my-drifter/types/GetSessionStatsForSite'

import { getSessionColumnDataForSite } from '@my-drifter/libs/CloudFunctionsApiHandler'

import useAppState from './useAppState'
import { useIsDesktop } from './useIsMobile'
import { useSelectedSiteContext } from './useSelectedSiteContext'
import { useUser } from './useUser'

const useSessionScreenData = () => {
  const { state } = useAppState()
  const isDesktop = useIsDesktop()
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [sessionsData, setSessionsData] = useState<GetSessionStatsForSiteResponse>([])
  const [fromDate, setFromDate] = useState<dayjs.Dayjs | null>(dayjs().subtract(11, 'months'))
  const [toDate, setToDate] = useState<dayjs.Dayjs | null>(dayjs())
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true)
      if (!selectedSiteId || !fromDate || !toDate) {
        return setIsLoadingData(false)
      }
      try {
        const data = await getSessionColumnDataForSite({
          siteId: selectedSiteId,
          fromDate: fromDate.format('YYYY-MM-DD'),
          toDate: toDate.format('YYYY-MM-DD')
        })
        setSessionsData(data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoadingData(false)
      }
    }

    fetchData()
  }, [selectedSiteId, fromDate, toDate])

  return {
    isAllowed,
    isLoadingUser,
    isDesktop,
    isLoadingData,
    sessionsData,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    selectedSiteId,
    setSelectedSiteId
  }
}

export default useSessionScreenData
