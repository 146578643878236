import styled from '@emotion/styled'
import { Box, Button } from '@mui/material'
import dayjs from 'dayjs'
import { memo, useEffect, useState } from 'react'
import { SvgLoader, SvgProxy } from 'react-svgmt'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'

import { HikerUserRole } from '@contracts/types/HikerUser'

import { fiveMinutesAgo } from '@pure/libs/DayJsHelper'

import {
  Bergshamra8007,
  Hogskolan7988,
  Honekullavagen8001,
  IcaCityBoras8011,
  ImportGatan7983,
  Kongahalla7989,
  Linne8000,
  Mariosgata7992,
  NivikaJkpg8003,
  PulsenBaras8004,
  Raberget7999,
  Redegatan8006,
  SevenNineNineFive,
  SevenNineNineOne,
  Skanor7998,
  Sockerbruksgatan7990
} from '@my-drifter/assets/siteSvg/SiteSvgs'
import TextsExtended from '@my-drifter/assets/texts_extended.json'
import FigmaImage from '@my-drifter/components/FigmaImage'
import FigmaText from '@my-drifter/components/FigmaText'
import { LAYOUT_HEADER_HEIGHT } from '@my-drifter/components/HardcodedSizes'
import Layout from '@my-drifter/components/Layout'
import { PortalColors } from '@my-drifter/components/PortalColors'
import { PortalSpacings } from '@my-drifter/components/PortalSpacings'
import SiteMapDrawer from '@my-drifter/components/SiteMapDrawer'
import ZoomControls from '@my-drifter/components/ZoomControls'
import useAppState from '@my-drifter/hooks/useAppState'
import { useGetLiveSiteMapStats } from '@my-drifter/hooks/useGetLiveSiteMapStats'
import { useSelectedSiteContext } from '@my-drifter/hooks/useSelectedSiteContext'
import { useSite } from '@my-drifter/hooks/useSite'
import { useUser } from '@my-drifter/hooks/useUser'
import { Elevation1 } from '@my-drifter/libs/Elevation'
import Images from '@my-drifter/libs/Images'
import { RadiusSmall } from '@my-drifter/libs/Radii'
import { getSlotsToRender, RenderedSlot } from '@my-drifter/libs/SiteMapHelper'
import { LabelMediumStrong, TitleSmall } from '@my-drifter/libs/Typography'

import { Spacings } from '@web-components/enums/Spacings'

import NotAuthorizedScreen from './NotAuthorizedScreen'

function DrawerCollapsed({ toggleDrawer }: { toggleDrawer: () => void }) {
  return (
    <Button
      onClick={toggleDrawer}
      sx={{
        ...TitleSmall,
        ...Elevation1,
        padding: PortalSpacings.x2,
        gap: PortalSpacings.x2,
        right: '10px',
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        position: 'fixed'
      }}
    >
      <FigmaText textKey={TextsExtended.sitespanelHeading} />
      <FigmaImage imageKey={Images.rightPanelOpen} />
    </Button>
  )
}

const SiteMapScreen = () => {
  const { state } = useAppState()
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)
  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ADMIN, HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const siteSVGs = {
    '7983': ImportGatan7983,
    '7988': Hogskolan7988,
    '7989': Kongahalla7989,
    '7990': Sockerbruksgatan7990,
    '7991': SevenNineNineOne,
    '7992': Mariosgata7992,
    '7995': SevenNineNineFive,
    '7998': Skanor7998,
    '7999': Raberget7999,
    '8000': Linne8000,
    '8001': Honekullavagen8001,
    '8003': NivikaJkpg8003,
    '8004': PulsenBaras8004,
    '8006': Redegatan8006,
    '8007': Bergshamra8007,
    '8009': Redegatan8006, // Redegatan is split into two sites
    '8010': NivikaJkpg8003, // Nivika Jönköping is split into two sites
    '8011': IcaCityBoras8011
  }

  const firstSiteSvg = user?.siteIds ? user.siteIds[0] : Object.keys(siteSVGs)[0]
  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()
  const currentSite = selectedSiteId ? selectedSiteId : firstSiteSvg

  const [showStats, setShowStats] = useState(false)
  const [selectedSegment, setSelectedSegment] = useState<string>('')
  const [renderedSlots, setRenderedSlots] = useState<RenderedSlot[]>([])
  const { data: siteConfig, isLoading: isLoadingSiteConfig } = useSite(currentSite)
  useEffect(() => {
    setSelectedSegment('')
  }, [currentSite])
  useEffect(() => {
    if (siteConfig && !isLoadingSiteConfig) {
      setRenderedSlots(getSlotsToRender(siteConfig.segments, selectedSegment))
    }
  }, [siteConfig, selectedSegment])

  const { data: liveSiteMapStats, isLoading: isLoadingLiveSiteMapStats } = useGetLiveSiteMapStats(currentSite)

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />
  return (
    <Layout
      isDashboardScreen
      siteSVGs={siteSVGs}
      selectedSiteId={selectedSiteId ? selectedSiteId : firstSiteSvg}
      setSelectedSiteId={setSelectedSiteId}
      isLoading={isLoadingUser}
    >
      <div style={{ display: 'flex', height: '100%', width: '100%', overflow: 'hidden' }}>
        <LiveIndicator>
          LIVE
          <FigmaImage imageKey={Images.liveIcon}></FigmaImage>
        </LiveIndicator>
        <Box
          sx={{
            padding: PortalSpacings.x6,
            background: PortalColors.surfaceLevelSecond,
            width: '100%',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: PortalSpacings.x6
          }}
        >
          <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0} wheel={{ step: 0.2 }}>
            <TransformComponent>
              <StyledSvgLoader path={siteSVGs[currentSite]}>
                <>
                  {liveSiteMapStats &&
                    !isLoadingLiveSiteMapStats &&
                    liveSiteMapStats.map((stats, index) => {
                      const { slot, timestamp } = stats
                      // record_time is stored in a firestore timestamp, so we need to convert it to a dayjs object
                      const convertedTimestamp = dayjs.unix(timestamp.seconds)
                      if (convertedTimestamp.isAfter(fiveMinutesAgo().format())) {
                        return <SvgProxy key={index} selector={`#${slot}_parked-slot`} visibility="visible"></SvgProxy>
                      }
                    })}

                  {renderedSlots.map((slot, index) => {
                    return (
                      <SvgProxy
                        key={`unselected_slot_${index}`}
                        selector={`#${slot.slot}`}
                        opacity={slot.dimmed ? '30%' : '100%'}
                      />
                    )
                  })}
                </>
              </StyledSvgLoader>
            </TransformComponent>
            <ZoomControls selectedSiteId={selectedSiteId} />
          </TransformWrapper>
        </Box>
        {showStats && (
          <SiteMapDrawer
            siteConfig={siteConfig}
            isLoadingSiteConfig={isLoadingSiteConfig}
            liveSiteMapStats={liveSiteMapStats}
            toggleDrawer={() => setShowStats(false)}
            selectedSegment={selectedSegment}
            setSelectedSegment={setSelectedSegment}
          />
        )}
        {!showStats && <DrawerCollapsed toggleDrawer={() => setShowStats(true)} />}
      </div>
    </Layout>
  )
}

const LiveIndicator = styled.div`
  color: ${PortalColors.surfaceWhite};
  font-family: ${LabelMediumStrong.fontFamily};
  font-size: ${LabelMediumStrong.fontSize};
  line-height: ${LabelMediumStrong.lineHeight};
  font-style: ${LabelMediumStrong.fontStyle};
  font-weight ${LabelMediumStrong.fontWeight};
  letter-spacing: ${LabelMediumStrong.letterSpacing};
  text-transform: 'uppercase';
  position: fixed;
  background: ${PortalColors.infographicRed};
  border: 2px solid ${PortalColors.infographicRed};
  border-radius: ${RadiusSmall};
  display: inline-flex;
  padding: ${PortalSpacings.x1} ${PortalSpacings.x2};
  justify-content: center;
  align-items: center;
  gap: ${PortalSpacings.x1};
  margin-left: ${PortalSpacings.x6};
}`

const StyledSvgLoader = styled(SvgLoader)`
  max-width: 80vw;
  max-height: calc(80vh - ${LAYOUT_HEADER_HEIGHT});
  width: 100%;
  height: 100%;
  align-self: center;
  margin-bottom: ${Spacings.xxxl};
`

export default memo(SiteMapScreen)
