import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

import { PortalColors } from './PortalColors'
import { PortalSpacings } from './PortalSpacings'

const ProgressContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%'
})

const ProgressLabel = styled(Typography)({
  position: 'absolute',
  fontFamily: 'Poppins',
  width: '100%',
  color: PortalColors.textWhite,
  padding: PortalSpacings.x2,
  textAlign: 'start',
  fontWeight: 600,
  letterSpacing: '0.15px',
  fontSize: '16px'
})

const ProgressBar = styled(LinearProgress)(() => ({
  width: '100%',
  height: 36,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: `repeating-linear-gradient(
        45deg,
        ${PortalColors.progressGradientPrimary},
        ${PortalColors.progressGradientPrimary} 10px,
        ${PortalColors.progressGradientSecondary} 10px,
        ${PortalColors.progressGradientSecondary} 20px
    )`
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: PortalColors.surfaceHighlightPrimary
  }
}))

interface GradientProgressBarProps {
  value: number
  showsApproximateValue?: boolean
}

export default function GradientProgressBar({
  value,
  showsApproximateValue: showsApproximateValue
}: GradientProgressBarProps) {
  const labelText = showsApproximateValue ? `~${value}%` : `${value}%`
  return (
    <ProgressContainer>
      <ProgressBar variant="determinate" value={value} />
      <ProgressLabel>{labelText}</ProgressLabel>
    </ProgressContainer>
  )
}
