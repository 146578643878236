import { Box, Button, Divider, Drawer } from '@mui/material'
import { useEffect, useState } from 'react'

import { LiveSiteMapStats } from '@contracts/types/LiveSiteMapStats'
import { Site } from '@contracts/types/Site'

import { formatText } from '@pure/libs/TextRepositoryHelper'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import { Elevation3 } from '@my-drifter/libs/Elevation'
import Images from '@my-drifter/libs/Images'
import {
  gateStatsFromTodayByDeviceId,
  getOrCreateStatsForType,
  getSegmentStats,
  recentStatsBySlotFromLiveMapStats,
  SEGMENT_KEY_ALL_SEGMENTS,
  SegmentSlotData
} from '@my-drifter/libs/SiteMapHelper'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { BodyMedium, BodySmall, TitleLarge, TitleMediumStrong } from '@my-drifter/libs/Typography'

import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'
import GradientProgressBar from './GradientProgressBar'
import { LAYOUT_HEADER_HEIGHT, LAYOUT_SITEMAP_DRAWER_WIDTH } from './HardcodedSizes'
import ParkingStatsHeading, { ParkingStatsHeadingType } from './ParkingStatsHeading'
import ParkingStatsTable from './ParkingStatsTable'
import { PortalColors } from './PortalColors'
import { PortalSpacings } from './PortalSpacings'
import SelectableChip from './SelectableChip'

interface SiteMapDrawerProps {
  siteConfig?: Site
  isLoadingSiteConfig?: boolean
  liveSiteMapStats?: LiveSiteMapStats[]
  toggleDrawer: () => void
  selectedSegment: string
  setSelectedSegment: (segment: string) => void
}

export default function SiteMapDrawer({
  siteConfig,
  isLoadingSiteConfig,
  liveSiteMapStats,
  toggleDrawer,
  selectedSegment,
  setSelectedSegment
}: SiteMapDrawerProps) {
  const [segmentStats, setSegmentStats] = useState<{ [segment: string]: SegmentSlotData }>({})
  const [segmentNames, setSegmentNames] = useState<string[]>([])
  const showSegmentSelector = segmentNames.length > 1
  const currentOccupancyTitleText =
    selectedSegment === ''
      ? getFigmaText(TextsExtended.sitescurrentOccupancySite)
      : getFigmaText(TextsExtended.sitescurrentOccupancySegment)

  useEffect(() => {
    if (siteConfig && !isLoadingSiteConfig) {
      const recentStatsBySlot = liveSiteMapStats ? recentStatsBySlotFromLiveMapStats(liveSiteMapStats) : {}
      const gateStatsByDeviceId = liveSiteMapStats ? gateStatsFromTodayByDeviceId(liveSiteMapStats) : {}
      const sNames = siteConfig ? siteConfig?.segments.map((s) => s.name) : []
      setSegmentNames(sNames)
      const sStats = getSegmentStats(
        siteConfig?.segments ? siteConfig.segments : [],
        recentStatsBySlot,
        gateStatsByDeviceId
      )
      setSegmentStats(sStats)
    }
  }, [siteConfig, liveSiteMapStats])

  const currentSegment = segmentStats[selectedSegment] ?? segmentStats[SEGMENT_KEY_ALL_SEGMENTS] ?? {}
  const totalData = getOrCreateStatsForType('total', currentSegment)
  const commonData = currentSegment['common']
  const evData = currentSegment['ev']
  const markedData = currentSegment['marked']
  const handicapData = currentSegment['handicap']
  const totalOccupancy = siteConfig?.isGateOnlySite ? (siteConfig.occupancy ?? 0) : totalData.occupied
  const totalOccupancyPercentage = Math.round((totalOccupancy / totalData.available) * 100)

  const currentOccupancyText = formatText(getFigmaText(TextsExtended.sitesoccupancyCurrent), [
    totalOccupancy,
    totalData?.available ? totalData.available : 0
  ])

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      sx={{
        ...Elevation3,
        display: 'flex',
        flexDirection: 'column',
        width: LAYOUT_SITEMAP_DRAWER_WIDTH,
        flexShrink: 0,
        anchor: 'right',
        '& .MuiDrawer-paper': {
          overflowY: 'auto',
          paddingTop: LAYOUT_HEADER_HEIGHT,
          width: LAYOUT_SITEMAP_DRAWER_WIDTH,
          boxSizing: 'border-box'
        },
        background: PortalColors.surfaceWhite
      }}
    >
      <Box sx={{ overflowY: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: LAYOUT_HEADER_HEIGHT,
            padding: PortalSpacings.x4,
            color: PortalColors.textPrimary,
            '. &span': {
              color: PortalColors.textPrimary
            }
          }}
        >
          <Box sx={{ ...TitleLarge, color: PortalColors.textPrimary }}>
            <FigmaText textKey={TextsExtended.sitespanelHeading} />
          </Box>
          <Button onClick={toggleDrawer}>
            <FigmaImage imageKey={Images.rightPanelClose} />
          </Button>
        </Box>
        {showSegmentSelector && (
          <Box sx={{ padding: PortalSpacings.x4 }}>
            <Box sx={{ ...TitleMediumStrong, color: PortalColors.textPrimary, paddingBottom: PortalSpacings.x4 }}>
              <FigmaText textKey={TextsExtended.sitestitleSegments} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                padding: `${PortalSpacings.x1} ${PortalSpacings.x2}`,
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: PortalSpacings.x2
              }}
            >
              <SelectableChip
                selected={selectedSegment === ''}
                key="All"
                label="All"
                onClick={() => setSelectedSegment('')}
              />
              {segmentNames.map((segment) => (
                <SelectableChip
                  selected={segment === selectedSegment}
                  key={segment}
                  label={segment}
                  onClick={() => setSelectedSegment(segment)}
                />
              ))}
            </Box>
          </Box>
        )}

        <Divider />
        <Box
          sx={{
            padding: `${PortalSpacings.x4} ${PortalSpacings.x4} ${PortalSpacings.x6} ${PortalSpacings.x4}`
          }}
        >
          <Box
            sx={{
              ...TitleMediumStrong,
              color: PortalColors.textPrimary
            }}
          >
            {currentOccupancyTitleText}
          </Box>
          <Box sx={{ paddingBottom: PortalSpacings.x6 }}>
            <GradientProgressBar value={totalOccupancyPercentage} showsApproximateValue={siteConfig?.isGateOnlySite} />
            <Box sx={{ ...BodySmall, color: PortalColors.textPrimary, paddingTop: PortalSpacings.x2 }}>
              {currentOccupancyText}
            </Box>
          </Box>
          {siteConfig?.isGateOnlySite && (
            <Box
              sx={{
                ...BodyMedium,
                boxShadow: `0px 1px 3px 0px ${PortalColors.elevationShadowPrimary}, 0px 2px 4px 0px ${PortalColors.elevationShadowSecondary}`,
                display: 'flex',
                color: PortalColors.textPrimary,
                background: PortalColors.infographicLightBlue,
                border: `1px ${PortalColors.borderInformation}`,
                borderRadius: '4px',
                padding: `${PortalSpacings.x3} ${PortalSpacings.x4} ${PortalSpacings.x3} ${PortalSpacings.x3}`,
                gap: PortalSpacings.x3
              }}
            >
              <Box>
                <FigmaImage imageKey={Images.info} />
              </Box>
              <Box>
                <FigmaText textKey={TextsExtended.sitesdescriptionUnreadSlots} />
              </Box>
            </Box>
          )}
          {!siteConfig?.isGateOnlySite && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {commonData && (
                <ParkingStatsHeading type={ParkingStatsHeadingType.Common} available={commonData.available} />
              )}
              {commonData && <ParkingStatsTable slotStats={commonData} />}
              {evData && <ParkingStatsHeading type={ParkingStatsHeadingType.EV} available={evData.available} />}
              {evData && <ParkingStatsTable slotStats={evData} />}
              {handicapData && (
                <ParkingStatsHeading type={ParkingStatsHeadingType.Handicap} available={handicapData.available} />
              )}
              {handicapData && <ParkingStatsTable slotStats={handicapData} />}
              {markedData && (
                <ParkingStatsHeading type={ParkingStatsHeadingType.NoParking} available={markedData.available} />
              )}
              {markedData && <ParkingStatsTable slotStats={markedData} />}
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  )
}
