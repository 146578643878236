enum InternalColors {
  blue = '#1076B0',
  blue100 = '#C3E6FA',
  blue500 = '#138BCF',
  colorGray100 = '#D3DEED',
  emerald100 = '#ACFAD6',
  emerald400 = '#23B080',
  emerald500 = '##06946D',
  white = '#FFFFFF',
  OffWhite = '#F0F3FA',
  navigationBlue = '#0A004A',
  purple100 = '#E3DFFF',
  purple500 = '#6D63F1',
  purple800 = '#2300A3',
  purple900 = '#130068',
  red100 = '#FAD3CF',
  red500 = '#D34444',
  red600 = '#B83336',
  grey600 = '#546378',
  textActionTertiary = '#5347D6',
  paginationHovered = '#C4C0FF',
  gray = '#64748B',
  colorTransparencyLightest = '#0A004A08',
  colorTransparencyLight = '#0A004A16'
}

export const PortalColors = {
  borderDecorativeLow: InternalColors.colorGray100,
  borderInformation: InternalColors.blue500,
  topBorderBackground: InternalColors.colorGray100,
  buttonHovered: InternalColors.colorGray100,
  textWhite: InternalColors.OffWhite,
  surfaceError: InternalColors.red100,
  surfaceWhite: InternalColors.white,
  surfaceHighlightPrimary: InternalColors.purple500,
  textSelectedWhite: InternalColors.white,
  navigationBlue: InternalColors.navigationBlue,
  navigationPressed: InternalColors.purple800,
  navigationHovered: InternalColors.purple900,
  infographicsBrand: InternalColors.purple500,
  surfaceLevelSecond: InternalColors.OffWhite,
  surfaceLevelThird: InternalColors.colorGray100,
  surfaceActionPrimary: InternalColors.purple500,
  surfaceActionTertiary: InternalColors.paginationHovered,
  surfaceChipPurple: InternalColors.purple100,
  textPrimary: InternalColors.purple900,
  textSecondary: InternalColors.grey600,
  textTertiary: InternalColors.textActionTertiary,
  elevationShadowPrimary: InternalColors.colorTransparencyLightest,
  elevationShadowSecondary: InternalColors.colorTransparencyLight,
  iconAccent: InternalColors.emerald500,
  iconError: InternalColors.red600,
  infographicEmerald: InternalColors.emerald400,
  surfaceHighlightTertiary: InternalColors.emerald100,
  infographicLightBlue: InternalColors.blue100,
  infographicBlue: InternalColors.blue,
  infographicRed: InternalColors.red500,
  progressGradientPrimary: InternalColors.purple900,
  progressGradientSecondary: InternalColors.purple800
} as const

export type PortalColors = (typeof PortalColors)[keyof typeof PortalColors]
