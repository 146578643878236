import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import * as yup from 'yup'

import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import { HikerUser } from '@contracts/types/HikerUser'
import { Permit } from '@contracts/types/Permit'

import { Colors } from '@pure/assets/Colors'
import { formatPayerAlias } from '@pure/libs/PhoneNumberHelper'

import { getOrCreateUserSecure } from '@my-drifter/libs/CloudFunctionsApiHandler'
import { upsertPermit } from '@my-drifter/libs/DBApiHandler'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import RightSideDrawer from './RightSideDrawer'
import TextFieldForm from './TextFieldForm'

interface RightSideVehiclesDrawerProps {
  permit?: Permit
  selectedSiteId?: string
  selectedSitePermitDebtorId?: string
  isDrawerOpen: boolean
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>
}

const AddStaffMemberDrawer = ({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId,
  isDrawerOpen,
  setIsDrawerOpen
}: RightSideVehiclesDrawerProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isStaffMemberAlreadyAdded, setIsStaffMemberAlreadyAdded] = useState(false)

  const schema = yup.object().shape({
    phone: yup.string().required(Texts.textMyPortalPhoneValidation)
  })

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: { name?: string; phone: string }) => {
    setIsLoading(true)
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) return
    const formattedPhone = formatPayerAlias(data.phone)

    const user: HikerUser = await getOrCreateUserSecure({ phone: `+${formattedPhone}` })
    const userId = user?.id?.toString()

    if (!userId) return setIsLoading(false)

    const userAlreadyExists = userId && permit.staffMembersIds && permit.staffMembersIds?.indexOf(userId) !== -1

    if (userAlreadyExists) {
      setIsStaffMemberAlreadyAdded(true)
      return setIsLoading(false)
    }

    const updatedPermit = {
      ...permit,
      staffMembersIds: [...(permit.staffMembersIds || []), userId]
    } as Permit

    await upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, updatedPermit)

    setIsDrawerOpen(false)
    setIsLoading(false)
  }

  useEffect(() => {
    if (!isDrawerOpen) {
      reset({ phone: '' })
      setIsStaffMemberAlreadyAdded(false)
    }
  }, [isDrawerOpen])

  return (
    <RightSideDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}>
      <FigmaText textKey={Texts.textMyPortalAddUserHeader} />
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Box fullWidth gap={Spacings.xs}>
          <FigmaText textKey={Texts.textMyPortalAddUserPhoneInput} />
          <Controller
            control={control}
            name="phone"
            render={({ field, fieldState }) => (
              <TextFieldForm
                {...field}
                value={field.value}
                fullWidth
                fieldState={fieldState}
                autoFocus
                placeholder="+46..."
              />
            )}
          />
        </Box>
        <Button fullWidth type="submit" loading={isLoading} textKey={Texts.textButtonsAddButton} />
      </StyledForm>
      {isStaffMemberAlreadyAdded && (
        <ErrorBox fullWidth fullPadding>
          <FigmaText textKey={Texts.textMyPortalErrorUserAlreadyAdded} />
        </ErrorBox>
      )}
    </RightSideDrawer>
  )
}

const StyledForm = styled.form`
  display: flex;
  gap: ${Spacings.s};
  flex-direction: column;
  margin-top: ${Spacings.m};
`

const ErrorBox = styled(Box)`
  margin-top: ${Spacings.s};
  border: 1px solid ${Colors.yellow1};
  background-color: ${Colors.red1};
  border-radius: ${BorderRadiusesPx.minimum};
`

export default memo(AddStaffMemberDrawer)
