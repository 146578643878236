import { z } from 'zod'

import { LBESiteConfig } from '@contracts/types/LBESiteConfig'
import { OffenseType } from '@contracts/types/OffenseType'
import { SiteFeaturesSchema } from '@contracts/types/SiteFeatures'
import { SiteOffenseFeesSchema } from '@contracts/types/SiteFeatures'
import { SiteInvoiceSettingsSchema } from '@contracts/types/SiteInvoiceSettings'
import { SiteSessionLogicSchema } from '@contracts/types/SiteSessionLogic'
import { SiteStaffContactDetailScema } from '@contracts/types/SiteStaffContactDetail'

import { VehicleEUCategory } from '@pure/swagger/VehicleEUCategory'

export type Site = z.infer<typeof SiteSchema> & { lbeConfig?: LBESiteConfig } // we need lbeConfig for now; it might be still used in Retool

export type SiteSegment = z.infer<typeof SiteSegmentSchema>

export type UpsertSiteRequest = z.infer<typeof UpsertSiteSchema>

export type SitePricing = z.infer<typeof SitePricingSchema>

export type SitePricingConfigurationRelative = z.infer<typeof SitePricingConfigurationRelativeSchema>

export type SitePricingConfigurationAbsolute = z.infer<typeof SitePricingConfigurationAbsoluteSchema>

export type SitePricingConfigurationItemBase = z.infer<typeof SitePricingConfigurationItemBaseSchema>

export type SitePricingConfigurationRelativeItem = z.infer<typeof SitePricingConfigurationRelativeItemSchema>

export type SitePricingConfigurationAbsoluteItem = z.infer<typeof SitePricingConfigurationAbsoluteItemSchema>

export type SiteSlot = z.infer<typeof SiteSlotSchema>

export type SitePricingConfiguration = z.infer<typeof SitePricingConfigurationSchema>

export type SitePriceConfigurationItem = SitePricingConfigurationRelativeItem | SitePricingConfigurationAbsoluteItem

export type SessionPricingItem = {
  item: SitePriceConfigurationItem
  startedAt: string
  endedAt: string
  cost: number
  freeDurationMinutes?: number
}

const SitePricingConfigurationItemBaseSchema = z.strictObject({
  freeDurationMinutes: z.number().optional() // used by absolute price item
})

const SitePricingConfigurationAbsoluteItemSchema = SitePricingConfigurationItemBaseSchema.extend({
  fromTime: z.string(),
  toTime: z.string(),
  perHourPrice: z.number(),
  dayOfWeek: z.number().optional(),
  dayOfWeeks: z.array(z.number()).optional()
})

const SitePricingConfigurationRelativeItemSchema = SitePricingConfigurationItemBaseSchema.extend({
  intervalMinutes: z.number().optional(), // inteval in minutes i.e 60 means one hour
  perHourPrice: z.number()
})

const SitePricingTimeConfigurationBaseSchema = z.strictObject({
  offenseType: z.nativeEnum(OffenseType).optional(),
  freeDurationMinutesForQuickStopOffense: z.number().optional()
})

const SitePricingConfigurationRelativeSchema = SitePricingTimeConfigurationBaseSchema.extend({
  type: z.literal('relative'),
  freeDurationMinutes: z.number().optional(), // used by relative price item
  items: z.array(SitePricingConfigurationRelativeItemSchema)
})

const SitePricingConfigurationAbsoluteSchema = SitePricingTimeConfigurationBaseSchema.extend({
  type: z.literal('absolute'),
  dailyFreeDurationMinutes: z.number().optional(), // per-day free minutes; should be used either here or in the item (not both)
  items: z.array(SitePricingConfigurationAbsoluteItemSchema)
})

const SitePricingConfigurationSchema = z.union([
  SitePricingConfigurationRelativeSchema,
  SitePricingConfigurationAbsoluteSchema
])

export const SitePricingSchema = z.strictObject({
  id: z.string(),
  name: z.string().optional(), // used for Customer Support and in Session Event logs
  parking: SitePricingConfigurationSchema,
  offense: z.array(SitePricingConfigurationSchema).optional(),
  overstayOffenseFeeVehicleTypeWhiteList: z.array(z.nativeEnum(VehicleEUCategory)).optional()
})

const SiteSlotSchema = z.strictObject({
  pricing: SitePricingSchema.optional(),
  placeCode: z.string(),
  type: z.enum(['ev', 'marked', 'handicap', 'quick_stop']).optional()
})

export const SlotType = {
  Ev: 'ev',
  Marked: 'marked',
  Handicap: 'handicap',
  QuickStop: 'quick_stop'
} as const

export type SlotType = (typeof SlotType)[keyof typeof SlotType]

const SiteGateSchema = z.strictObject({
  deviceId: z.string(),
  type: z.enum(['internal']).optional()
})

const SiteSegmentSchema = z.strictObject({
  id: z.string(),
  name: z.string(),
  pricing: SitePricingSchema,
  sessionLogic: SiteSessionLogicSchema.optional(),
  slots: z.array(SiteSlotSchema),
  gates: z.array(SiteGateSchema)
})

// Remote controlled doors that are managed through the active session in the App.
const DoorConfig = z.strictObject({
  doorSlug: z.string(),
  enabled: z.boolean()
})

const SiteBaseSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  address: z.string().optional(),
  enablePayments: z.boolean().optional(),
  enabledDoors: z.array(DoorConfig).optional(),
  enableAutoEndSession: z.boolean().optional(),
  enableBillectaInvoiceForRegsteredUsersOnSessionClosed: z.boolean().optional(),
  enableBillectaInvoiceHandicappedAttachmentForOffenses: z.boolean().optional(),
  enableOverrideChargeSlotOffenseWithPermit: z.boolean().optional(),
  enableExperimentalSessionStartLogic: z.boolean().optional(),
  isGateOnlySite: z.boolean().optional(),
  isTestSite: z.boolean().optional(),
  whitelistPlateNumberList: z.array(z.string()).optional(),
  invoiceSettings: SiteInvoiceSettingsSchema.optional(),
  fleetPlateNumberList: z.array(z.string()).optional(),
  gatePlateNumberList: z.array(z.string()).optional(),
  timezone: z.string().optional(), // 'Europe/Stockholm'
  staffContactDetails: z.array(SiteStaffContactDetailScema).optional(),
  logo: z.string().optional(),
  numberOfGuests: z.record(z.number()).optional(),
  occupancy: z.number().optional(),
  segmentOccupancy: z.record(z.string(), z.number()).optional(),
  guestParkingAdditionalConditions: z.string().optional(),
  sessionLogic: SiteSessionLogicSchema.optional(), // should be required once configured
  pricing: SitePricingSchema.optional(), // should be required once configured
  cameraList: z
    .array(
      z.object({
        name: z.string(), // "CAM116"
        id: z.string(), // "60"
        deviceId: z.string(), // "116"
        parkId: z.string(), // "7988"
        weight: z.number().optional()
      })
    )
    .optional(),
  segments: z.array(SiteSegmentSchema),
  doorConfigs: z.array(DoorConfig).optional()
})

export const SiteSchema = z.object({}).merge(SiteBaseSchema).merge(SiteFeaturesSchema).merge(SiteOffenseFeesSchema)

export const UpsertSiteSchema = SiteSchema.partial().strict()
