import styled from '@emotion/styled'
import { Box } from '@mui/material'

import TextsExtended from '@my-drifter/assets/texts_extended.json'
import Images from '@my-drifter/libs/Images'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import { TitleSmallStrong } from '@my-drifter/libs/Typography'

import FigmaImage from './FigmaImage'
import { PortalColors } from './PortalColors'
import { PortalSpacings } from './PortalSpacings'

const ParkingStatsHeadingContainer = styled(Box)(() => ({
  ...TitleSmallStrong,
  color: PortalColors.textPrimary,
  display: 'flex',
  flex: '1 0 0',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  padding: `${PortalSpacings.x1} 0 ${PortalSpacings.x3} 0`
}))

export const ParkingStatsHeadingType = {
  Common: 'common',
  EV: 'ev',
  NoParking: 'no-park',
  Handicap: 'handicap'
}

export type ParkingStatsHeadingType = (typeof ParkingStatsHeadingType)[keyof typeof ParkingStatsHeadingType]

function HeadingIcon({ type }: { type: ParkingStatsHeadingType }) {
  switch (type) {
    case ParkingStatsHeadingType.Common:
      return <FigmaImage imageKey={Images.publicParking} />

    case ParkingStatsHeadingType.EV:
      return <FigmaImage imageKey={Images.charging} />

    case ParkingStatsHeadingType.NoParking:
      return <FigmaImage imageKey={Images.noParking} />

    case ParkingStatsHeadingType.Handicap:
      return <FigmaImage imageKey={Images.handicap} />
  }
}

export default function ParkingStatsHeading({ type, available }: { type: ParkingStatsHeadingType; available: number }) {
  let title
  switch (type) {
    case ParkingStatsHeadingType.Common:
      title = getFigmaText(TextsExtended.sitesrolePublic)
      break
    case ParkingStatsHeadingType.EV:
      title = getFigmaText(TextsExtended.sitesroleCharging)
      break
    case ParkingStatsHeadingType.NoParking:
      title = getFigmaText(TextsExtended.sitesroleMarked)
      break
    case ParkingStatsHeadingType.Handicap:
      title = getFigmaText(TextsExtended.handicapSlot)
      break
  }
  return (
    <ParkingStatsHeadingContainer>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: PortalSpacings.x2 }}>
        <HeadingIcon type={type} />
        <div>{title}</div>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: PortalSpacings.x2 }}>
        <div>{available}</div>
        <Box sx={{ color: PortalColors.textSecondary, fontWeight: 500 }}>{getFigmaText(TextsExtended.slots)}</Box>
      </Box>
    </ParkingStatsHeadingContainer>
  )
}
