import CheckIcon from '@mui/icons-material/Check'
import { Chip } from '@mui/material'

import { LabelMediumStrong } from '@my-drifter/libs/Typography'

import { PortalColors } from './PortalColors'
import { PortalSpacings } from './PortalSpacings'

interface SelectableChipProps {
  selected: boolean
  label: string
  key: string
  onClick: () => void
}

const ChipStyle = {
  ...LabelMediumStrong,
  color: PortalColors.textPrimary,
  borderRadius: PortalSpacings.x1,
  backgroundColor: PortalColors.surfaceLevelThird,
  textTransform: 'uppercase'
}

const SelectedChipStyle = {
  ...ChipStyle,
  border: `1px solid ${PortalColors.surfaceHighlightPrimary}`,
  backgroundColor: PortalColors.surfaceChipPurple
}

export default function SelectableChip({ selected, label, onClick }: SelectableChipProps) {
  if (selected) {
    return <Chip key={label} sx={SelectedChipStyle} icon={<CheckIcon />} label={label} onClick={onClick} />
  }
  return <Chip key={label} sx={ChipStyle} label={label} onClick={onClick} />
}
