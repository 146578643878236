import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { memo, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { SlackWebhooks } from '@contracts/enums/SlackWebhooks'
import { BorderRadiusesPx } from '@contracts/types/BorderRadixes'
import {
  Permit,
  PermitUserDeletionRequest,
  UserPermitConnection,
  UserPermitConnectionSchemaStatus
} from '@contracts/types/Permit'

import { Colors } from '@pure/assets/Colors'
import { formatPayerAlias } from '@pure/libs/PhoneNumberHelper'

import { slack } from '@web-js/libs/SlackHelperClient'

import { useAuthUserContext } from '@my-drifter/hooks/useFirebaseAuthUser'
import { useUser } from '@my-drifter/hooks/useUser'
import { terminatePermitConnection } from '@my-drifter/libs/CloudFunctionsApiHandler'
import { deletePermitUserDeletion, getUserByPhoneNumber, upsertPermit } from '@my-drifter/libs/DBApiHandler'
import { getFigmaText } from '@my-drifter/libs/TextRepository'
import Texts from '@my-drifter/libs/Texts'

import { Spacings } from '@web-components/enums/Spacings'

import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import FullScreenModal from './FullScreenModal'
import RightSideDrawer from './RightSideDrawer'
import TextFieldForm from './TextFieldForm'

type DeleteVehicleDrawerProps = {
  permit?: Permit
  selectedSiteId?: string
  selectedSitePermitDebtorId?: string
  isDrawerOpen: boolean
  setIsDrawerOpen: (isOpen: boolean) => void
  userPermitConnection?: UserPermitConnection
}

const UpdateUserDrawer = ({
  permit,
  selectedSiteId,
  selectedSitePermitDebtorId,
  isDrawerOpen,
  setIsDrawerOpen,
  userPermitConnection
}: DeleteVehicleDrawerProps) => {
  const [isUserAlreadyAdded, setIsUserAlreadyAdded] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const { data: user } = useUser(userPermitConnection?.userId)
  const { authUserId } = useAuthUserContext()

  const schema = yup.object().shape({
    name: yup.string(),
    phone: yup.string().required(getFigmaText(Texts.textMyPortalPhoneValidation))
  })

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema)
  })

  const onDeleteUser = async () => {
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) return

    // call cloud function removePermitFromUser
    await terminatePermitConnection({
      permitId: permit.id,
      updatedById: authUserId, // who's updating the permit connection
      userId: userPermitConnection?.userId, // which user with permit connection to terminate
      source: 'my-drifter'
    })

    setIsDeleteModalOpen(false)
    setIsDrawerOpen(false)
  }

  const onSubmit = async (data: { phone: string }) => {
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) return

    const userPermitConnectionToEdit = permit.userPermitConnections?.find(
      (permitUserPermitConnection) => permitUserPermitConnection.userId === userPermitConnection?.userId
    )

    const formattedPhone = formatPayerAlias(data.phone)

    const user = await getUserByPhoneNumber(`+${formattedPhone}`)

    if (!user) return

    const userAlreadyExists = permit.userPermitConnections?.find(
      (connection) => String(connection.userId) === String(user.id)
    )

    if (userAlreadyExists) {
      setIsUserAlreadyAdded(true)
      return
    }

    const updatedPermit = {
      ...permit,
      userPermitConnections: permit.userPermitConnections?.map((permitUserPermitConnection) =>
        permitUserPermitConnection.userId === userPermitConnectionToEdit?.userId
          ? {
              ...permitUserPermitConnection,
              userId: String(user.id)
            }
          : permitUserPermitConnection
      )
    }

    upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, updatedPermit)

    setIsDrawerOpen(false)
  }

  const onReactivateUser = async () => {
    if (!permit || !selectedSiteId || !selectedSitePermitDebtorId) return

    const userPermitConnectionToEdit = permit.userPermitConnections?.find(
      (permitUserPermitConnection) => permitUserPermitConnection.userId === userPermitConnection?.userId
    )

    const updatedPermit = {
      ...permit,
      userPermitConnections: permit.userPermitConnections?.map((permitUserPermitConnection) =>
        permitUserPermitConnection.userId === userPermitConnectionToEdit?.userId
          ? {
              createdAt: dayjs().format(),
              status: UserPermitConnectionSchemaStatus.ACTIVE,
              updatedAt: dayjs().format(),
              updatedBy: 'MyDrifter',
              acceptedAt: dayjs().format(),
              userId: permitUserPermitConnection.userId
            }
          : permitUserPermitConnection
      )
    } as Permit

    await slack(
      `my-drifter: onReactivateUser admin (id: ${authUserId}) called to reactivate user ${userPermitConnection?.userId} for permit ${permit.id} (${permit.name}) in site ${permit.siteId}`,
      SlackWebhooks.OPS_HIKER_USER_PERMIT_CONNECTIONS
    )

    await upsertPermit(selectedSiteId, selectedSitePermitDebtorId, permit.id, updatedPermit)
    if (userPermitConnectionToEdit) {
      // make sure user-permit is not scheduled for deletion
      const deleteRequest: PermitUserDeletionRequest = {
        permitId: permit.id,
        userId: userPermitConnectionToEdit.userId
      }
      await deletePermitUserDeletion(deleteRequest)
    }

    setIsDrawerOpen(false)
  }

  useEffect(() => {
    if (!isDrawerOpen) {
      reset({ name: '', phone: '' })
      setIsUserAlreadyAdded(false)
    }
  }, [isDrawerOpen])

  return (
    <>
      <RightSideDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}>
        {userPermitConnection?.status !== UserPermitConnectionSchemaStatus.TERMINATED && (
          <Box fullWidth>
            <FigmaText textKey={Texts.textMyPortalEditUserHeader} />
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <Box fullWidth gap={Spacings.xs}>
                <FigmaText textKey={Texts.textGuestInputStaffName} />
                <Controller
                  control={control}
                  name="name"
                  render={({ field, fieldState }) => (
                    <TextFieldForm
                      {...field}
                      value={field.value}
                      fullWidth
                      fieldState={fieldState}
                      autoFocus
                      placeholder={`${user?.firstName} ${user?.lastName}`}
                    />
                  )}
                />
              </Box>
              <Box fullWidth gap={Spacings.xs}>
                <FigmaText textKey={Texts.textMyPortalAddUserPhoneInput} />
                <Controller
                  control={control}
                  name="phone"
                  render={({ field, fieldState }) => (
                    <TextFieldForm
                      {...field}
                      value={field.value}
                      fullWidth
                      fieldState={fieldState}
                      autoFocus
                      placeholder={user?.phone}
                    />
                  )}
                />
              </Box>
              <Button fullWidth type="submit" textKey={Texts.textButtonsSaveButton} />
            </StyledForm>
            {isUserAlreadyAdded && (
              <ErrorBox fullWidth fullPadding>
                <FigmaText textKey={Texts.textMyPortalErrorUserAlreadyAdded} />
              </ErrorBox>
            )}
            <RemoveVehicleButton fullWidth align="center" top onClick={() => setIsDeleteModalOpen(true)}>
              <FigmaText textKey={Texts.textMyPortalRemoveUserPermit} />
            </RemoveVehicleButton>
          </Box>
        )}
        {userPermitConnection?.status === UserPermitConnectionSchemaStatus.TERMINATED && (
          <>
            <FigmaText textKey={Texts.textMyPortalEditUserHeader} />
            <Box fullWidth top bottom gap={Spacings.xxs}>
              <FigmaText
                textKey={Texts.textMyPortalUserNameCss}
                text={`${user?.firstName} ${user?.lastName} - ${user?.id}`}
              />
              <FigmaText textKey={Texts.textMyPortalUserNameCss} text={user?.phone} />
            </Box>
            <Button fullWidth textKey={Texts.textButtonsCtaReactivatePermit} onClick={onReactivateUser} />
          </>
        )}
      </RightSideDrawer>
      <FullScreenModal
        isOpen={isDeleteModalOpen}
        titleTextKey={Texts.textNotificationsNotificationVerifyRemovalOfUserFromPermit}
        leftButtonTextKey={Texts.textButtonsCtaVerifyAndRemoveUser}
        leftButtonOnClick={onDeleteUser}
        rightButtonTextKey={Texts.textButtonsManageCancel}
        rightButtonOnClick={() => setIsDeleteModalOpen(false)}
      />
    </>
  )
}

const StyledForm = styled.form`
  display: flex;
  gap: ${Spacings.s};
  flex-direction: column;
  margin-top: ${Spacings.m};
`

const ErrorBox = styled(Box)`
  margin-top: ${Spacings.s};
  border: 1px solid ${Colors.yellow1};
  background-color: ${Colors.yellow4};
  border-radius: ${BorderRadiusesPx.minimum};
`

const RemoveVehicleButton = styled(Box)`
  color: red;
  cursor: pointer;
  text-decoration: underline;

  span {
    color: red !important;
  }
`

export default memo(UpdateUserDrawer)
