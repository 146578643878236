import dayjs from 'dayjs'

import { PlaceCode } from '@contracts/types/CameraEvent'
import { LiveSiteMapStats } from '@contracts/types/LiveSiteMapStats'
import { OffenseType } from '@contracts/types/OffenseType'
import { SiteSegment, SlotType } from '@contracts/types/Site'

import { tenMinutesAgo, twoHoursAgo } from '@pure/libs/DayJsHelper'

export type SlotStatsType = SlotType | 'common' | 'total'

export const SEGMENT_KEY_ALL_SEGMENTS = 'ALL_SEGMENTS'

export type SegmentSlotData = { [type: string]: SlotStats }
export interface SlotStats {
  type: SlotStatsType
  available: number
  occupied: number
  regular: number
  permits: number
  offenses: number
  handicapOffenses: number
  markedSlotOffenses: number
  vehiclePositionOffenses: number
  quickstopOverstayOffenses: number
  chargeSlotOffenses: number
  guests: number
}

export function getOrCreateStatsForType(slotType: SlotStatsType, segmentSlotData: SegmentSlotData) {
  const statsForType = segmentSlotData[slotType]
  if (!statsForType) {
    segmentSlotData[slotType] = {
      type: slotType,
      available: 0,
      occupied: 0,
      regular: 0,
      permits: 0,
      offenses: 0,
      handicapOffenses: 0,
      markedSlotOffenses: 0,
      vehiclePositionOffenses: 0,
      quickstopOverstayOffenses: 0,
      chargeSlotOffenses: 0,
      guests: 0
    }
  }
  return segmentSlotData[slotType]
}

export interface RenderedSlot {
  slot: string
  dimmed: boolean
}

export function getSlotsToRender(segments: SiteSegment[], currentSegment: string) {
  return segments
    .map((segment) =>
      segment.slots.map((slot) => ({
        slot: slot.placeCode,
        dimmed: currentSegment === '' ? false : segment.name !== currentSegment
      }))
    )
    .flat()
}

export function recentStatsBySlotFromLiveMapStats(siteMapStats: LiveSiteMapStats[]) {
  const res = siteMapStats.reduce<{ [slot: string]: LiveSiteMapStats }>((acc, curr) => {
    const recordTime = dayjs.unix(curr.timestamp.seconds)
    if (recordTime.isAfter(tenMinutesAgo()) && curr.slot) {
      acc[curr.slot] = curr
    }
    return acc
  }, {})
  return res
}

export function gateStatsFromTodayByDeviceId(siteMapStats: LiveSiteMapStats[]) {
  const res = siteMapStats.reduce((acc, curr) => {
    if (!acc[curr.deviceId]) {
      acc[curr.deviceId] = []
    }
    if (curr.slot === PlaceCode.Entry || curr.slot === PlaceCode.Exit || curr.slot === PlaceCode.Gate) {
      const recordTime = dayjs.unix(curr.timestamp.seconds)
      if (recordTime.isAfter(twoHoursAgo())) {
        acc[curr.deviceId].push(curr)
      }
    }
    return acc
  }, {})
  return res
}

export function getSegmentStats(
  segments: SiteSegment[],
  recentStatsBySlot: { [slot: string]: LiveSiteMapStats },
  gateStatsByDeviceId: { [deviceId: string]: LiveSiteMapStats[] }
) {
  const segmentStats: { [segment: string]: SegmentSlotData } = {}
  const allSegmentsSlotData: { [type: string]: SlotStats } = {} // Data for all slots, we add to it in the same loop so we don't traverse all slots twice
  segments.forEach((segment) => {
    const segmentSlotData: { [type: string]: SlotStats } = {}

    segment.slots.forEach((slot) => {
      const slotStats = recentStatsBySlot[slot.placeCode]
      processSlotType(slot.type ? slot.type : 'common', segmentSlotData, slotStats)
      processSlotType(slot.type ? slot.type : 'common', allSegmentsSlotData, slotStats)

      // Don't count marked slots towards site total as no-park slots should not count towards a sites capacity
      if (slot.type !== SlotType.Marked) {
        processSlotType('total', segmentSlotData, slotStats)
        processSlotType('total', allSegmentsSlotData, slotStats)
      }
    })

    segment.gates.forEach((gate) => {
      const gateStats = gateStatsByDeviceId[gate.deviceId]
      gateStats?.forEach((gateStat) => {
        processGateStat('common', segmentSlotData, gateStat)
        processGateStat('common', allSegmentsSlotData, gateStat)
        processGateStat('total', segmentSlotData, gateStat)
        processGateStat('total', allSegmentsSlotData, gateStat)
      })
    })

    segmentStats[segment.name] = segmentSlotData
  })

  segmentStats[SEGMENT_KEY_ALL_SEGMENTS] = allSegmentsSlotData
  return segmentStats
}

export interface SegmentMap {
  [name: string]: SegmentStats
}
export interface SegmentStats {
  slots: number
  evSlots: number
  markedSlots: number
  handicapSlots: number
  evOccupancy: number
  handicapOccupancy: number
  markedSlotOccupancy: number
  totalOccupancy: number
}

function processSlotType(slotType: SlotStatsType, segmentSlotData: SegmentSlotData, slotStats: LiveSiteMapStats) {
  const isOccupied = !!slotStats
  const isOffense = !!slotStats?.isOffense
  const isPermitParking = !!slotStats?.isPermitParking
  const isGuestParking = !!slotStats?.isGuestParking
  const isRegularParking = isOccupied && !isPermitParking && !isOffense && !isGuestParking

  const statsForType = getOrCreateStatsForType(slotType, segmentSlotData)
  segmentSlotData[slotType] = {
    type: slotType,
    available: statsForType.available + 1,
    occupied: isOccupied ? statsForType.occupied + 1 : statsForType.occupied,
    regular: isRegularParking ? statsForType.regular + 1 : statsForType.regular,
    permits: isPermitParking ? statsForType.permits + 1 : statsForType.permits,
    offenses: isOffense ? statsForType.offenses + 1 : statsForType.offenses,
    handicapOffenses:
      slotStats?.offenseType && slotStats.offenseType === OffenseType.DisabledSlot
        ? statsForType.handicapOffenses + 1
        : statsForType.handicapOffenses,
    markedSlotOffenses:
      slotStats?.offenseType && slotStats.offenseType === OffenseType.MarkedSlot
        ? statsForType.markedSlotOffenses + 1
        : statsForType.markedSlotOffenses,
    vehiclePositionOffenses:
      slotStats?.offenseType && slotStats.offenseType === OffenseType.VehiclePosition
        ? statsForType.vehiclePositionOffenses + 1
        : statsForType.vehiclePositionOffenses,
    quickstopOverstayOffenses:
      slotStats?.offenseType && slotStats.offenseType === OffenseType.QuickstopOverstay
        ? statsForType.quickstopOverstayOffenses + 1
        : statsForType.quickstopOverstayOffenses,
    chargeSlotOffenses:
      slotStats?.offenseType && slotStats.offenseType === OffenseType.ChargeSlotOffense
        ? statsForType.chargeSlotOffenses + 1
        : statsForType.chargeSlotOffenses,
    guests: isGuestParking ? statsForType.guests + 1 : statsForType.guests
  }
}

// Appends to the same object as processSlotType, but without incrementing the number of available slots
function processGateStat(slotType: SlotStatsType, segmentSlotData: SegmentSlotData, slotStats: LiveSiteMapStats) {
  const statsForType = getOrCreateStatsForType(slotType, segmentSlotData)

  const isPermitParking = slotStats.isPermitParking
  const isGuestParking = slotStats.isGuestParking
  const isRegularParking = !isPermitParking && !isGuestParking

  segmentSlotData[slotType] = {
    type: slotType,
    available: statsForType.available,
    occupied: statsForType.occupied + 1,
    regular: isRegularParking ? statsForType.regular + 1 : statsForType.regular,
    permits: isPermitParking ? statsForType.permits + 1 : statsForType.permits,
    offenses: statsForType.offenses,
    handicapOffenses: statsForType.handicapOffenses,
    markedSlotOffenses: statsForType.markedSlotOffenses,
    vehiclePositionOffenses: statsForType.vehiclePositionOffenses,
    quickstopOverstayOffenses: statsForType.quickstopOverstayOffenses,
    chargeSlotOffenses: statsForType.chargeSlotOffenses,
    guests: isGuestParking ? statsForType.guests + 1 : statsForType.guests
  }
}
