import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import { doc, setDoc } from 'firebase/firestore'
import { memo, useState } from 'react'

import Collections from '@contracts/enums/Collections'
import { CustomPricing, CustomPricingItem } from '@contracts/types/CustomPricing'
import { HikerUserRole } from '@contracts/types/HikerUser'

import { useFirestoreQuery } from '@web-js/hooks/useFirestoreQuery'

import FullScreenSpinner from '@my-drifter/components/FullScreenSpinner'
import Layout from '@my-drifter/components/Layout'
import Loader from '@my-drifter/components/Loader'
import useAppState from '@my-drifter/hooks/useAppState'
import { useSelectedSiteContext } from '@my-drifter/hooks/useSelectedSiteContext'
import { useUser } from '@my-drifter/hooks/useUser'
import { db } from '@my-drifter/libs/FirebaseOptions'

import NotAuthorizedScreen from './NotAuthorizedScreen'

const upsertCustomPricing = async (data: CustomPricing) => {
  await setDoc(doc(db, `${Collections.CUSTOM_PRICING}/${data.siteId}`), data, { merge: true })

  return data
}

const CustomPricingEditorScreen = () => {
  const { state } = useAppState()
  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()

  const [editingItemIndex, setEditingItemIndex] = useState<number>(-1)
  const [editingItem, setEditingItem] = useState<CustomPricingItem | null>(null)
  const [currentLanguage, setCurrentLanguage] = useState<'sv' | 'en'>('sv')

  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const { data, isLoading: isLoadingData } = useFirestoreQuery<CustomPricing>(
    doc(db, `${Collections.CUSTOM_PRICING}/${selectedSiteId}`),
    {
      fnName: 'useCustomPricing',
      enabled: Boolean(selectedSiteId)
    }
  )

  const isLoading = isLoadingData || isLoadingUser

  const isAllowed =
    !isLoadingUser &&
    user?.role &&
    [HikerUserRole.ROOT].includes(user.role) &&
    user?.siteIds &&
    user?.siteIds?.length > 0

  const handleEditItem = (itemIndex) => {
    if (!data || !data.items[itemIndex]) return

    setEditingItemIndex(itemIndex)
    setEditingItem({ ...data.items[itemIndex] })
  }

  const handleSaveItem = async () => {
    const siteId = data?.siteId ? data.siteId : selectedSiteId

    if (!siteId || !editingItem) return

    try {
      const existingItems = data?.items || []

      const items =
        editingItemIndex === -1
          ? [...existingItems, editingItem]
          : existingItems.map((item, idx) => (idx === editingItemIndex ? editingItem : item))

      await upsertCustomPricing({
        siteId,
        items
      })
    } catch (error) {
      console.error('Error saving item:', error)
    } finally {
      resetEditor()
    }

    resetEditor()
  }

  const handleDeleteItem = async (itemIndex: number) => {
    if (!data) return

    try {
      const newPricing = {
        ...data,
        items: data.items.filter((_, idx) => idx !== itemIndex)
      }

      await upsertCustomPricing(newPricing)
    } catch (error) {
      console.error('Error deleting item:', error)
    }
  }

  const handleAddItem = () => {
    if (!selectedSiteId) return

    const newItem: CustomPricingItem = {
      text: { sv: 'Ny pris-sektion', en: 'New Pricing Section' }
    }

    try {
      setEditingItem(newItem)
    } catch (error) {
      console.error('Error adding item:', error)
    }
  }

  const resetEditor = () => {
    setEditingItemIndex(-1)
    setEditingItem(null)
  }

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  if (!selectedSiteId) {
    return (
      <Layout
        selectedSiteId={selectedSiteId}
        setSelectedSiteId={(id) => {
          setSelectedSiteId(id)
        }}
        childrenContainerStyle={{ backgroundColor: 'white' }}
      >
        <Container maxWidth="md" sx={{ mt: 4, mb: 8 }}>
          <Typography>Select site before continuing</Typography>
        </Container>
      </Layout>
    )
  }

  return !isLoadingUser ? (
    <Layout
      selectedSiteId={selectedSiteId}
      setSelectedSiteId={(id) => {
        setSelectedSiteId(id)
      }}
      childrenContainerStyle={{ backgroundColor: 'white' }}
    >
      <Container maxWidth="sm" sx={{ mt: 4, mb: 8 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Tabs value={currentLanguage} onChange={(_, newValue: 'sv' | 'en') => setCurrentLanguage(newValue)}>
            <Tab value="sv" label="Swedish" disabled={isLoading} />
            <Tab value="en" label="English" disabled={isLoading} />
          </Tabs>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddItem}
            startIcon={<AddIcon />}
            disabled={isLoading}
          >
            Add Segment
          </Button>
        </Box>

        {selectedSiteId && isLoadingData && (
          <Container maxWidth="sm" sx={{ mt: 4, display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <Loader />
          </Container>
        )}

        {!isLoading && (!data || data.items.length === 0) ? (
          <Container
            maxWidth="sm"
            sx={{
              mt: 6,
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              gap: 2
            }}
          >
            <Typography>There&rsquo;s no custom pricing configuration for this site yet.</Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddItem}
              startIcon={<AddIcon />}
              disabled={isLoading}
              sx={{ alignSelf: 'center' }}
            >
              Add Segment
            </Button>
          </Container>
        ) : (
          !isLoading &&
          data?.items.map((item, index) => (
            <Card key={`${index}-${item.text}-${item.name}`} sx={{ mb: 2 }}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="h2"
                  gutterBottom
                  sx={{
                    fontFamily: 'Montserrat',
                    fontSize: 14,
                    fontWeight: 700,
                    textDecoration: 'underline',
                    color: '#202020',
                    mb: 1
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ whiteSpace: 'pre-line', lineHeight: 1.6, fontFamily: 'Inter', color: '#202020' }}
                >
                  {item.text[currentLanguage]}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" startIcon={<EditIcon />} onClick={() => handleEditItem(index)}>
                  Edit
                </Button>
                <Button size="small" startIcon={<DeleteIcon />} onClick={() => handleDeleteItem(index)}>
                  Delete
                </Button>
              </CardActions>
            </Card>
          ))
        )}
        <Dialog open={Boolean(editingItem)} onClose={resetEditor}>
          <DialogTitle>Edit Item</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              fullWidth
              value={editingItem?.name || ''}
              onChange={(e) => setEditingItem((prev) => (prev ? { ...prev, name: e.target.value } : null))}
            />
            <TextField
              margin="dense"
              label="Swedish Text"
              fullWidth
              multiline
              rows={4}
              value={editingItem?.text.sv || ''}
              onChange={(e) =>
                setEditingItem((prev) => (prev ? { ...prev, text: { ...prev.text, sv: e.target.value } } : null))
              }
            />
            <TextField
              margin="dense"
              label="English Text"
              fullWidth
              multiline
              rows={4}
              value={editingItem?.text.en || ''}
              onChange={(e) =>
                setEditingItem((prev) => (prev ? { ...prev, text: { ...prev.text, en: e.target.value } } : null))
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={resetEditor}>Cancel</Button>
            <Button onClick={handleSaveItem}>Save</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Layout>
  ) : (
    <FullScreenSpinner />
  )
}

export default memo(CustomPricingEditorScreen)
